import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useNumberInput,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';

export type WorkOrderPartFieldChangeStockModalProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  partId: number;
  stock: number;
  quantity: number;
  onConfirmPartButtonClick: (partId: number, quantity: number) => void;
  onDeletePartButtonClick: (partId: number) => void;
};

const WorkOrderPartFieldChangeStockModal: FC<WorkOrderPartFieldChangeStockModalProps> = (
  props: WorkOrderPartFieldChangeStockModalProps
) => {
  const {
    partId,
    stock,
    quantity,
    isOpen,
    onClose,
    onDeletePartButtonClick,
    onConfirmPartButtonClick,
  } = props;

  const { t, t_ns } = useTranslation(TASK);

  const [localQuantity, setLocalQuantity] = useState<number>(quantity);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    min: 1,
    defaultValue: localQuantity,
    max: stock,
    precision: 0,
    onChange: (_, value: number) => setLocalQuantity(value),
  });

  const handleDeletePartButtonClicked = () => {
    onDeletePartButtonClick(partId);
  };

  const handleConfirmButtonClicked = () => {
    onConfirmPartButtonClick(partId, localQuantity);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'lg', md: 'sm' }}>
      <ModalOverlay />
      <ModalContent m={0} alignSelf={{ base: 'flex-end', md: 'center' }}>
        <ModalHeader>{t('part.number-of-used')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <HStack width='full'>
              <IconButton
                {...getDecrementButtonProps()}
                colorScheme='red'
                icon={<MdRemove />}
                size='lg'
                isRound={true}
                aria-label='decrement replenishment count'
              />
              <Input {...getInputProps()} textAlign='center' size='lg' />
              <IconButton
                {...getIncrementButtonProps()}
                colorScheme='green'
                icon={<MdAdd />}
                size='lg'
                isRound={true}
                aria-label='increment replenishment count'
              />
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter px={2} py={4}>
          <Flex
            width='full'
            justifyContent='flex-end'
            backgroundColor={{ base: 'neutral.0', md: 'unset' }}
          >
            <Button
              variant='outline'
              colorScheme='red'
              width={{ base: '50%', md: 'auto' }}
              onClick={() => handleDeletePartButtonClicked()}
            >
              {t_ns('delete-from-task')}
            </Button>

            <Button
              ml='2'
              variant='solid'
              colorScheme='primary'
              width={{ base: '50%', md: '120px' }}
              onClick={() => handleConfirmButtonClicked()}
            >
              {t('ok')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkOrderPartFieldChangeStockModal;
