import { WorkOrderFormDefaultDataType } from './WorkOrderForm.types';

export const WORK_ORDER_FORM_DEFAULT_VALUES: WorkOrderFormDefaultDataType = {
  title: '',
  assetId: undefined,
  productId: undefined,
  description: '',
  dueDate: undefined,
  stoppageStartAt: undefined,
  stoppageEndAt: undefined,
  status: 'open',
  stoppageReasonId: null,
};
