import { useConfirmModal } from '@/context/ConfirmModalContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { type WorkOrderPriority, type WorkOrderStatus } from '@/graphql/types';
import { toUserInputs } from '@/modules/users';
import { WorkOrderFormDataType } from '@/modules/workOrders/hooks/useAddWorkOrder';
import {
  IWorkOrderAssigneeInput,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  WorkOrderDoneStatus,
} from '@/modules/workOrders/types/workOrder';
import { IWorkOrderCustomField } from '@/modules/workOrders/types/workOrderCustomField';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { convertCustomFieldValueToInputValue } from '@/modules/workOrders/utils/customFields';
import {
  CheckListFormValueType,
  generateCheckListInitFormValue,
} from '@/utils/customFields/checkListCustomFields';
import {
  formatDateToYYYYMMDDForInput,
  formatDateToYYYYMMDDHHmmForInput,
  isFromDateAfterToDate,
} from '@/utils/date/date';
import { useFiles } from '@/utils/file/useFiles';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { CheckListFieldCardType } from '../../checkList/WorkOrderCheckListFieldCard';
import { CustomFieldFileValueType } from '../../customFields/CustomFieldFileValueType';
import { WorkOrderPartCardType } from '../../parts/WorkOrderPartField';
import { WORK_ORDER_FORM_DEFAULT_VALUES } from './WorkOrderForm.constants';
import { useWorkOrderFormQuery } from './WorkOrderForm.generated';
import { WorkOrderFormDefaultDataType, WorkOrderFormProps } from './WorkOrderForm.types';

type UseWorkOrderFormProps = WorkOrderFormProps;

export const useWorkOrderForm = (props: UseWorkOrderFormProps) => {
  const {
    submit,
    pause,
    cancel,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    workOrder,
    customFieldFileValues = [],
    shouldConfirmStatusChange = false,
    checkListTemplate,
  } = props;

  const { t, t_errors } = useTranslation(TASK);
  const { confirm } = useConfirmModal();
  const { getFileUploadUrls, cacheFileSrc } = useFiles();

  const { defaultWorkOrderTemplate } = useTemplateContext();

  const template = useMemo(() => {
    return (props.template || defaultWorkOrderTemplate) as IWorkOrderTemplateBase;
  }, [props.template, defaultWorkOrderTemplate]);

  const { customFields, fieldOrders } = template;

  const { data: formData } = useWorkOrderFormQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [localCustomFieldFileValues, setLocalCustomFieldFileValues] =
    useState<CustomFieldFileValueType[]>(customFieldFileValues);

  const handleAddCustomFieldFileValues = useCallback(
    async (fileValues: CustomFieldFileValueType[]) => {
      if (!onAddCustomFieldFileValues || (await onAddCustomFieldFileValues(fileValues))) {
        setLocalCustomFieldFileValues((prev) => [...prev, ...fileValues]);
        fileValues.forEach((file) => cacheFileSrc(file.fileId, file.src));
      }
    },
    [cacheFileSrc, onAddCustomFieldFileValues]
  );

  const handleRemoveCustomFieldFileValue = async (fileId: string, customFieldId: number) => {
    if (
      !onRemoveCustomFieldFileValue ||
      (await onRemoveCustomFieldFileValue(fileId, customFieldId))
    ) {
      setLocalCustomFieldFileValues((values) => {
        const index = values.findIndex((value) => value.fileId === fileId);
        values.splice(index, 1);
        return [...values];
      });
    }
  };

  const handleUpdateCustomFieldFileValue = useCallback(
    async (
      fileId: string,
      params: {
        fileId: string;
        contentType: string;
        name: string;
        src: string;
      }
    ) => {
      await onUpdateCustomFieldFileValue?.(fileId, params);
      setLocalCustomFieldFileValues((prev) => {
        return prev.map((entry) =>
          entry.fileId === fileId ? { ...entry, ...params, key: entry.fileId } : entry
        );
      });
      cacheFileSrc(params.fileId, params.src);
    },
    [cacheFileSrc, onUpdateCustomFieldFileValue]
  );

  const initialParts = () => {
    if (!workOrder || !workOrder.parts) {
      return [];
    }

    return workOrder.parts.map((entry) => {
      if (!entry.part) throw new Error(t_errors('impossible'));
      return {
        partId: entry.part.id,
        name: entry.part.name,
        stock: entry.part.stock,
        quantity: entry.quantity,
        unit: entry.part.unit || undefined,
        cost: entry.part.cost || undefined,
      };
    });
  };

  const initialCheckLists = () => {
    if (!workOrder || !workOrder.checkLists) {
      return [];
    }

    return workOrder.checkLists.map((entry) => {
      return {
        id: entry.id,
        name: entry.template.name,
        templateId: entry.template.id,
      };
    });
  };

  const initialAssignees = () => {
    if (workOrder) {
      return toUserInputs(workOrder.assignees);
    }
    return [];
  };

  const [workOrderParts, setWorkOrderParts] = useState<WorkOrderPartCardType[]>(initialParts);
  const [checkLists, setCheckLists] = useState<CheckListFieldCardType[]>(initialCheckLists);
  const [status, setStatus] = useState<WorkOrderStatus>(workOrder?.status || 'open');
  const [assignees, setAssignees] = useState<IWorkOrderAssigneeInput[]>(initialAssignees);

  const {
    customFieldTextValues,
    customFieldIntValues,
    customFieldFloatValues,
    customFieldSelectValues,
    customFieldDateValues,
    customFieldDatetimeValues,
    customFieldUserValues,
  } = convertCustomFieldValueToInputValue(workOrder || {});

  const [customFieldTextLocalValues, setCustomFieldTextLocalValues] =
    useState<IWorkOrderCustomFieldTextValueInput[]>(customFieldTextValues);
  const [customFieldIntLocalValues, setCustomFieldIntLocalValues] =
    useState<IWorkOrderCustomFieldIntValueInput[]>(customFieldIntValues);
  const [customFieldFloatLocalValues, setCustomFieldFloatLocalValues] =
    useState<IWorkOrderCustomFieldFloatValueInput[]>(customFieldFloatValues);
  const [customFieldSelectLocalValues, setCustomFieldSelectLocalValues] =
    useState<IWorkOrderCustomFieldSelectValueInput[]>(customFieldSelectValues);
  const [customFieldDatetimeLocalValues, setCustomFieldDatetimeLocalValues] =
    useState<IWorkOrderCustomFieldDatetimeValueInput[]>(customFieldDatetimeValues);
  const [customFieldDateLocalValues, setCustomFieldDateLocalValues] =
    useState<IWorkOrderCustomFieldDateValueInput[]>(customFieldDateValues);
  const [customFieldUserLocalValues, setCustomFieldUserLocalValues] =
    useState<IWorkOrderCustomFieldUserValueInput[]>(customFieldUserValues);

  const [selectedAssetId, setSelectedAssetId] = useState<number>();
  const [selectedProductId, setSelectedProductId] = useState<number>();

  const [priority, setPriority] = useState<WorkOrderPriority>(workOrder?.priority ?? 'none');

  const [checkListFormValue, setCheckListFormValue] = useState<CheckListFormValueType>(
    generateCheckListInitFormValue()
  );

  const onChangeWorkOrderPart = useCallback((parts: WorkOrderPartCardType[]) => {
    setWorkOrderParts(parts);
  }, []);

  const onChangeCheckLists = useCallback((checkLists: CheckListFieldCardType[]) => {
    setCheckLists(checkLists);
  }, []);

  const onChangeCheckListFormValue = (checkListFormValue: CheckListFormValueType) => {
    setCheckListFormValue(checkListFormValue);
  };

  const methods = useForm<WorkOrderFormDefaultDataType>({
    defaultValues: WORK_ORDER_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    register,
    getValues,
  } = methods;

  // FIXME: delete useEffect
  useEffect(() => {
    // 編集の場合
    if (workOrder) {
      const {
        title,
        assetId,
        description,
        stoppage,
        dueDate,
        priority,
        productId,
        stoppageReason,
      } = workOrder;
      setValue('title', title || '');
      setValue('assetId', assetId ?? undefined);
      setValue('productId', productId ?? undefined);
      setValue('description', description || '');
      setValue(
        'stoppageStartAt',
        stoppage ? formatDateToYYYYMMDDHHmmForInput(stoppage.startAt) : undefined
      );
      setValue(
        'stoppageEndAt',
        stoppage ? formatDateToYYYYMMDDHHmmForInput(stoppage.endAt) : undefined
      );
      setValue('dueDate', dueDate ? formatDateToYYYYMMDDForInput(dueDate) : undefined);
      setValue('stoppageReasonId', stoppageReason?.id ?? null);
      setSelectedAssetId(assetId ?? undefined);
      setSelectedProductId(productId ?? undefined);
      if (priority) setPriority(priority);
    }
  }, [setValue, workOrder]);

  const createAndValidateWorkOrderFromValue = (values: FieldValues): WorkOrderFormDataType => {
    const {
      title,
      assetId: assetIdString,
      productId: productIdString,
      description,
      stoppageStartAt,
      stoppageEndAt,
      dueDate,
      stoppageReasonId,
    } = values;

    if (isFromDateAfterToDate(stoppageStartAt, stoppageEndAt)) {
      setError('stoppageEndAt', {
        type: 'custom',
        message: t_errors('invalid-start-and-end-time'),
      });
    }

    const assetId = assetIdString ? Number(assetIdString) : undefined;
    const productId = productIdString ? Number(productIdString) : undefined;

    const localOrderCustomFieldFileValues = localCustomFieldFileValues.map((entry) => {
      return {
        customFieldId: entry.customFieldId,
        fileId: entry.fileId,
        contentType: entry.contentType,
        name: entry.name,
      };
    });

    const localCheckLists = checkLists.map((checkList) => {
      return { templateId: checkList.templateId };
    });

    const customFieldStampValues = checkListFormValue.customFieldStampValues.map(
      (customFieldStampValue) => {
        return {
          customFieldId: customFieldStampValue.customFieldId,
          stampedById: customFieldStampValue.stampedBy.id,
          stampedAt: customFieldStampValue.stampedAt,
        };
      }
    );

    const workOrder: WorkOrderFormDataType = {
      title,
      assetId,
      productId,
      description,
      status,
      dueDate: dueDate ? dueDate : null,
      assignees,
      priority,
      customFieldTextValues: customFieldTextLocalValues,
      customFieldIntValues: customFieldIntLocalValues,
      customFieldFloatValues: customFieldFloatLocalValues,
      customFieldSelectValues: customFieldSelectLocalValues,
      customFieldDatetimeValues: customFieldDatetimeLocalValues,
      customFieldDateValues: customFieldDateLocalValues,
      customFieldUserValues: customFieldUserLocalValues,
      stoppage: {
        startAt: stoppageStartAt ? new Date(stoppageStartAt) : undefined,
        endAt: stoppageEndAt ? new Date(stoppageEndAt) : undefined,
      },
      stoppageReasonId: stoppageReasonId ? parseInt(stoppageReasonId) : undefined,
      parts: workOrderParts.map((part) => {
        return { partId: part.partId, quantity: part.quantity };
      }),
      customFieldFileValues: localOrderCustomFieldFileValues,
      checkLists: checkListTemplate
        ? [{ templateId: checkListTemplate.id, ...checkListFormValue, customFieldStampValues }]
        : localCheckLists,
      templateId: template.id,
    };

    return workOrder;
  };

  const onSubmit = (values: FieldValues) => {
    const workOrder = createAndValidateWorkOrderFromValue(values);
    submit(workOrder);
  };

  const onChangeAsset = (value?: number) => {
    setValue('assetId', value);
    setSelectedAssetId(value);
    if (value) {
      clearErrors('assetId');
    }
  };

  const onChangeProduct = (value?: number | string) => {
    setValue('productId', Number(value));
    setSelectedProductId(Number(value));
    if (value) {
      clearErrors('productId');
    }
  };

  const onChangeWorkOrderStatus = async (nextStatus: WorkOrderStatus) => {
    if (
      shouldConfirmStatusChange &&
      status === WorkOrderDoneStatus &&
      nextStatus !== WorkOrderDoneStatus
    ) {
      const result = await confirm(
        t('confirmation.task.revert-done-task'),
        undefined,
        t('actions.mark-as-incomplete'),
        {
          colorScheme: 'primary',
        },
        t('actions.cancel')
      );
      if (!result) {
        return;
      }
    }
    setStatus(nextStatus);
  };

  let displayCustomFields: IWorkOrderCustomField[] = [];

  if (customFields) {
    displayCustomFields = customFields.filter((customField) => {
      const { inputTimings } = customField;
      const hasNoInputTimings = inputTimings.length === 0;
      const includesInitialOrWorkOrderStatus =
        inputTimings.includes('initial') ||
        (workOrder && workOrder.status && inputTimings.includes(workOrder.status));

      return hasNoInputTimings || includesInitialOrWorkOrderStatus;
    });
  }

  const handleCancel = () => {
    cancel();
  };

  const handlePause = () => {
    if (!pause) throw new Error('pause is not defined');

    const values = getValues();
    const workOrder = createAndValidateWorkOrderFromValue(values);
    pause(workOrder);
  };

  return {
    handleCancel,
    handlePause,
    assigneesInputHandler: setAssignees,
    displayCustomFields,
    onChangeProduct,
    onChangeAsset,
    onSubmit,
    onChangeWorkOrderStatus,
    onChangeWorkOrderPart,
    onChangeCheckLists,
    onChangeCheckListFormValue,
    handleAddCustomFieldFileValues,
    handleRemoveCustomFieldFileValue,
    handleUpdateCustomFieldFileValue,
    workOrderParts,
    errors,
    handleSubmit,
    register,
    selectedAssetId,
    selectedProductId,
    formData,
    fieldOrders,
    setCustomFieldTextLocalValues,
    setCustomFieldIntLocalValues,
    setCustomFieldFloatLocalValues,
    setCustomFieldSelectLocalValues,
    setCustomFieldDatetimeLocalValues,
    setCustomFieldDateLocalValues,
    setCustomFieldUserLocalValues,
    customFieldTextLocalValues,
    customFieldIntLocalValues,
    customFieldFloatLocalValues,
    customFieldSelectLocalValues,
    customFieldDatetimeLocalValues,
    customFieldDateLocalValues,
    customFieldUserLocalValues,
    priority,
    setPriority,
    checkListFormValue,
    assignees,
    methods,
    localCustomFieldFileValues,
    isSubmitting,
    checkLists,
    getFileUploadUrls,
    status,
  } as const;
};
