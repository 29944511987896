import { useCurrencySymbol } from '@/modules/parts/hooks/useCurrencySymbol';
import { formatUnit } from '@/modules/parts/utils';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Button, Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { WorkOrderPartCardType } from './WorkOrderPartField';

export type WorkOrderPartFieldCardProps = {
  disabled: boolean;
  onPartClicked: (partId: number) => void;
  onPartNameClicked: (partId: number) => void;
  part: WorkOrderPartCardType;
};

const WorkOrderPartFieldCard: FC<WorkOrderPartFieldCardProps> = (
  props: WorkOrderPartFieldCardProps
) => {
  const { disabled, part, onPartClicked, onPartNameClicked } = props;

  const { name, quantity, unit, cost, currencyCode } = part;

  const { t } = useTranslation();
  const { combineCurrencySymbol } = useCurrencySymbol();

  let formattedUnit = formatUnit(unit);

  if (formattedUnit === '個') formattedUnit = t('pieces');

  return (
    <Box
      p={2}
      borderWidth={1}
      borderLeft='unset'
      borderRight='unset'
      borderTop='unset'
      _hover={{
        cursor: disabled ? 'unset' : 'pointer',
      }}
    >
      <HStack justifyContent='space-between'>
        <Stack align='stretch' textAlign='left' onClick={() => onPartNameClicked(part.partId)}>
          <Flex>
            <Text
              fontSize='md'
              letterSpacing='wide'
              color='primary.500'
              _hover={{ textDecoration: 'underline' }}
            >
              {name}
            </Text>
            <Spacer />
          </Flex>

          <Flex my={1}>
            {cost !== undefined && (
              <Text fontSize='sm' letterSpacing='wide' color='neutral.500'>
                {combineCurrencySymbol(cost, currencyCode)}
              </Text>
            )}
            <Spacer />
          </Flex>
        </Stack>
        <HStack onClick={() => !disabled && onPartClicked(part.partId)}>
          <Spacer />
          <Button isActive={disabled}>
            <Text>{quantity}</Text>
          </Button>
          <Text>{formattedUnit}</Text>
        </HStack>
      </HStack>
    </Box>
  );
};

export default WorkOrderPartFieldCard;
