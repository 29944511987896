import { WorkOrderForm, WorkOrderFormProps } from '@/components/workOrders/WorkOrderForm';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmModal } from '../../context/ConfirmModalContext';
import { WorkOrderFormDataType } from '../../modules/workOrders/hooks/useAddWorkOrder';
import { useScreenInfos } from '../../utils/mobiles/useScreenInfos';
import { Maybe } from '../types';

type CreateWorkOrderInputArgsType = Omit<WorkOrderFormProps, 'submit' | 'pause' | 'cancel'> & {
  submit: (workOrder: WorkOrderFormDataType) => Promise<boolean>;
  pause?: (workOrder: WorkOrderFormDataType) => Promise<boolean>;
  cancel?: () => void;
};

export const useCreateWorkOrder = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();
  const [modal, setModal] = useState<ReactNode>(undefined);

  const { isDesktop } = useScreenInfos();

  const createWorkOrderInput = useCallback(
    (args: CreateWorkOrderInputArgsType): Promise<Maybe<WorkOrderFormDataType>> =>
      new Promise((resolve) => {
        const { submit, cancel, pause } = args;

        const handleClose = () => {
          setModal(undefined);
          resolve(undefined);
        };

        const handleCancel = async () => {
          if (
            !(await confirm(
              t('confirmation.task.cancel-create-task'),
              t('confirmation.task.cancel-create-task-message'),
              t('actions.cancel'),
              {
                colorScheme: 'error',
              },
              t('actions.back-to-edit')
            ))
          )
            return;
          if (cancel) cancel();
          handleClose();
        };

        const handleSubmit = async (data: WorkOrderFormDataType) => {
          const result = await submit(data);
          if (result) handleClose();
        };

        const handlePause = async (data: WorkOrderFormDataType) => {
          if (!pause) throw new Error('Impossible to pause work order');
          const result = await pause(data);
          if (result) handleClose();
        };

        setModal(
          <Modal
            isOpen={true}
            onClose={handleClose}
            size={{ base: 'full', md: '2xl' }}
            isCentered
            scrollBehavior='inside'
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              {isDesktop && <ModalCloseButton />}
              <ModalBody
                p={0}
                height={{ base: '100vh', md: '80vh' }}
                data-testid='create-work-order-modal-body'
              >
                <WorkOrderForm
                  {...args}
                  submit={handleSubmit}
                  cancel={handleCancel}
                  pause={handlePause}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      }),
    [isDesktop, confirm, t]
  );

  return {
    createWorkOrderInput,
    CreateWorkOrderModal: modal,
  } as const;
};

export type WorkOrderFormDefaultValuesType = WorkOrderFormProps['workOrder'];
