import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkOrderPartField_PartFragment = (
  { __typename?: 'Part' }
  & Pick<Types.Part, 'id' | 'name' | 'stock' | 'unit' | 'cost' | 'currencyCode'>
);

export type WorkOrderPartFieldPartQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type WorkOrderPartFieldPartQuery = (
  { __typename?: 'Query' }
  & { part: (
    { __typename?: 'Part' }
    & Pick<Types.Part, 'id' | 'name' | 'stock' | 'unit' | 'cost' | 'currencyCode'>
  ) }
);

export const WorkOrderPartField_PartFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderPartField_Part"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Part"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"stock"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}},{"kind":"Field","name":{"kind":"Name","value":"cost"}},{"kind":"Field","name":{"kind":"Name","value":"currencyCode"}}]}}]} as unknown as DocumentNode;
export const WorkOrderPartFieldPartDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkOrderPartFieldPart"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"part"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkOrderPartField_Part"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderPartField_Part"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Part"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"stock"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}},{"kind":"Field","name":{"kind":"Name","value":"cost"}},{"kind":"Field","name":{"kind":"Name","value":"currencyCode"}}]}}]} as unknown as DocumentNode;

/**
 * __useWorkOrderPartFieldPartQuery__
 *
 * To run a query within a React component, call `useWorkOrderPartFieldPartQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderPartFieldPartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderPartFieldPartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkOrderPartFieldPartQuery(baseOptions: Apollo.QueryHookOptions<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables> & ({ variables: WorkOrderPartFieldPartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>(WorkOrderPartFieldPartDocument, options);
      }
export function useWorkOrderPartFieldPartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>(WorkOrderPartFieldPartDocument, options);
        }
export function useWorkOrderPartFieldPartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>(WorkOrderPartFieldPartDocument, options);
        }
export type WorkOrderPartFieldPartQueryHookResult = ReturnType<typeof useWorkOrderPartFieldPartQuery>;
export type WorkOrderPartFieldPartLazyQueryHookResult = ReturnType<typeof useWorkOrderPartFieldPartLazyQuery>;
export type WorkOrderPartFieldPartSuspenseQueryHookResult = ReturnType<typeof useWorkOrderPartFieldPartSuspenseQuery>;
export type WorkOrderPartFieldPartQueryResult = Apollo.QueryResult<WorkOrderPartFieldPartQuery, WorkOrderPartFieldPartQueryVariables>;